import Footer from "../components/Footer";
import { MailchimpForm1 } from "../components/mailchimpforms/HomeFormOne";
import Navbar from "../components/Navbar";
import React, { useState } from "react";
import ErrorAlert from "../components/alerts/errorAlert";
import LoadingAlert from "../components/alerts/loadingAlert";
import SucessAlert from "../components/alerts/sucessAlert";
import FadeInSection from "../components/FadeInSection";


const posts = [
  {
    title: "STEMEPA Presidential Volunteer Service Awards",
    href: "/articles/01-14-2025",
    description:
    "STEMEPA has been officialy acknowledged by the United States Government as a Certifying Organization for the President's Volunteer Service Award, the highest national volunteer recognition in the country!",
    date: "January 14, 2025",
    datetime: "2025-1-14",
  },
  {
    title: "STEM Detectives: Mystery Mission at Arena STEM [Finished]",
    href: "/articles/10-27-2024",
    description:
    "A mysterious crime has occurred on site at Arena STEM, with a group of evil science criminals leaving behind a cryptic path of hints!  Put on your trench coats and grab your magnifying glasses.  For more information, click below.",
    date: "October 10, 2024",
    datetime: "2024-10-27",
  },
  {
    title: "LEGO Racer STEM Night at Arena STEM",
    href: "/articles/05-29-2024",
    description:
    "On July 25 at 950 Industrial Blvd, STEMEPA is hosting LEGO Racer STEM Night along with Arena STEM for all local families in the Bucks County area!  For more information, click below.",
    date: "May 29, 2024",
    datetime: "2024-05-29",
  },
  {
    title: "The Pennsylvania Science and Innovation Competition (PSIC)",
    href: "/articles/06-20-2023",
    description:
    "The Pennsylvania Science and Innovation Competition (PSIC) is an annual regional science research competition organized by STEM Enrichment PA. For more information click below",
    date: "Jun 20, 2023",
    datetime: "2023-06-20",
  },
  {
    title: "Website Development Workshop",
    href: "/articles/01-21-2023",
    description:
    "STEMEPA will host an online workshop on the fundamentals of web development! A computer is necassary and we would like only ages 12+ to particpate. For more information click below",
    date: "Jan 21, 2023",
    datetime: "2023-01-21",
  },

  {
    title: "New Education Resources",
    href: "/articles/11-23-2023",
    description:
    "STEM Enrichment of Pennsylvania is proud to present our latest endevour to offer new education resources to anyone free of charge.",
    date: "November 23, 2023",
    datetime: "2023-11-23",
  },
  
];

export default function Events() {
  const [errorState, setErrorState] = useState(false);
  const [sucessState, setSucessState] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  return (
    <div className="bg-background">
      <Navbar />
      <div className=" px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
        <div className="relative mx-auto max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Past Events.
            </h2>
            <div>{errorState ? <ErrorAlert /> : null}</div>
            <div>{sucessState ? <SucessAlert /> : null}</div>
            <div>{loadingState ? <LoadingAlert /> : null}</div>
            <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:items-center lg:gap-5">
              <p className="text-xl text-gray-500">
                Get weekly updates on our events and how to join them by joining
                our newletter to the right!
              </p>
              <div className="">
                {/* MailChimp Form */}
                <MailchimpForm1
                  setSucessState={setSucessState}
                  setErrorState={setErrorState}
                  setLoadingState={setLoadingState}
                />
              </div>
            </div>
          </div>
          
          <div className="mt-6 grid gap-16 pt-10 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
            <FadeInSection>
            {posts.map((post) => (
              <div key={post.title} className="">
                <p className="text-sm text-gray-500">
                  <time dateTime={post.datetime}>{post.date}</time>
                </p>
                <a href={post.href} className="mt-2 block">
                  <p className="text-xl font-semibold text-gray-900">
                    {post.title}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    {post.description}
                  </p>
                </a>
                <div className="mt-3">
                  <a
                    href={post.href}
                    className="text-base font-semibold text-sm font-medium text-rose-500 hover:text-rose-700"
                  >
                    Read full story &rarr;
                  </a>
                </div>
                <br></br>
              </div>
              
            ))}
            </FadeInSection>
            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
