export default function ArticleBodyNovemberThird2023() {
  return (
    <div className="relative overflow-hidden py-16">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
        <div
          className="relative mx-auto h-full max-w-prose text-lg"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="block text-center text-lg font-semibold text-rose-600">
              New Education Resources:
            </span>
            <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              STEM Enrichment of Pennsylvania is proud to present our latest
              endevour to offer new education resources to anyone free of
              charge.
            </span>
          </h1>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Last month, we assigned all four officers of STEM Enrichment of PA
            to complete a series of PowerPoint videos detailing an aspect of new
            technology.
          </p>
        </div>
        <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
          <p>
            From the potential of AI to revolutionize healthcare to the
            awe-inspiring prospects of space tourism, the assignment traverses
            the diverse landscape of STEM, leaving no stone unturned. Genetic
            Engineering and CRISPR unveil a world where we can rewrite the code
            of life itself, while the latest developments in brain science
            promise to unlock the secrets of the mind. Finally, the allure of
            coding and game development beckons, where creativity intertwines
            with technology to shape the digital frontier.
          </p>
          <br></br>
          <h2 className=" font-semibold text-rose-600">Space Tourism</h2>
          <br></br>
          <p>
            TIn recent years, the concept of space tourism has transitioned from
            the realm of science fiction to a tangible reality, capturing the
            imagination of people around the world. As advancements in space
            exploration technology continue to unfold, the idea of civilians
            venturing beyond Earth's atmosphere has become more than just a
            dream. Space tourism promises to usher in a new era of human
            exploration, offering unprecedented opportunities for individuals to
            experience the vastness of the cosmos.
          </p>
          <br></br>
          <h3 className="text-rose-500">
            <a href="/assets/articles/11.23.23/Space Tourism (1).pdf" download>
              Download document summary <u>Here</u>
            </a>
          </h3>
          <br></br>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/vLZzJhyoGH0?si=7zhfOI0-GVbmiGQ8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <br></br>
          <h2 className=" font-semibold text-rose-600">Neurolink</h2>
          <br></br>
          <p>
            Neuralink Corporation, founded by Elon Musk in 2016, aims to
            revolutionize the interface between the human brain and computers.
            The primary goal of Neuralink is to develop advanced brain-machine
            interface (BMI) technologies that could potentially address a wide
            array of neurological disorders and disabilities and open up new
            possibilities for human enhancemen
          </p>

          <br></br>
          <h3 className="text-rose-500">
            <a href="/assets/articles/11.23.23/Neurolink doc.pdf" download>
              Download document summary <u>Here</u>
            </a>
          </h3>
          <br></br>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/84fPJxmKFyo?si=SrEdQ1oVaDaMrJ9a"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <br></br>
          <h2 className=" font-semibold text-rose-600">
            Coding and Game Design
          </h2>
          <br></br>
          <p>
            In the exhilarating realm of coding and game design, creativity
            meets logic to craft virtual worlds limited only by imagination.
            Delving into the intricate dance of ones and zeros, coding forms the
            backbone of this digital symphony, translating visions into
            interactive experiences. Aspiring game designers embark on a journey
            where lines of code shape landscapes, characters, and narratives,
            breathing life into pixels. The alchemy of coding and game design
            unleashes the power to create, innovate, and entertain, inviting
            enthusiasts to unravel the secrets behind their favorite digital
            adventures. Join the expedition into the heart of virtual creation,
            where keystrokes weave the tapestry of gaming magic.
          </p>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/d_iTHx6h1fI?si=CMkafGXmGJXrA2m_"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>

          <br></br>
          <h2 className=" font-semibold text-rose-600">
            Genetic engineering and CRISPR
          </h2>
          <br></br>
          <p>
            Genetic engineering and CRISPR represent formidable scientific
            capabilities poised to significantly influence the trajectory of
            life on Earth. Embark with us on an enlightening exploration to
            comprehend the foundational aspects of these extraordinary
            technologies.{" "}
          </p>


          <br></br>
          <h3 className="text-rose-500">
            <a href="/assets/articles/11.23.23/Genetic Engineering and CRISPR.pdf" download>
              Download document summary <u>Here</u>
            </a>
          </h3>
          <br></br>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Ei-nfJOzJ8E?si=bnrSWOxoHqpKmA5L"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <br></br>
          <br></br>
          <div className="object-scale-down">
            <figure>
              <img
                className="w-full rounded-lg h-1/3"
                src="../assets/articles/11.23.23/NASA.jpg"
                alt="Web Development Workshop"
              />
              <figcaption>
                Photo by{" "}
                <a href="https://unsplash.com/@nasa?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
                  NASA
                </a>{" "}
                on{" "}
                <a href="https://unsplash.com/photos/photo-of-outer-space-Q1p7bh3SHj8?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
                  Unsplash
                </a>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
}
