import { ChevronRightIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import ErrorAlert from "./alerts/errorAlert";
import LoadingAlert from "./alerts/loadingAlert";
import SucessAlert from "./alerts/sucessAlert";
import FadeInSection from "./FadeInSection";
import { MailchimpForm1 } from "./mailchimpforms/HomeFormOne";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css"; // Import default Splide styles
import { useEffect } from "react";
import '../index.css';

const RotatingText = ({ phrases, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true); // Controls fade-in and fade-out

  useEffect(() => {
    const timer = setInterval(() => {
      setFade(false); // Start fade-out

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        setFade(true); // Start fade-in after text changes
      }, 500); // Match fade-out duration
    }, interval);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [phrases, interval]);

  return (
    <span className="rotator-fade text-[#f43f5e]">
      <span
        className={`transition-opacity duration-500 ${
          fade ? "opacity-100" : "opacity-0"
        }`}
        style={{
          display: "inline-block",
        }}
      >
        {phrases[currentIndex]}
      </span>
    </span>
  );
};

export const transitionClasses = {
  enter: "transform transition ease-in-out duration-500 sm:duration-700",
  enterFrom: "translate-x-full",
  enterTo: "translate-x-0",
  leave: "transform transition ease-in-out duration-500 sm:duration-700",
  leaveFrom: "translate-x-0",
  leaveTo: "translate-x-full",
};

export default function Hero() {
  const [errorState, setErrorState] = useState(false);
  const [sucessState, setSucessState] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  return (
    <div>
      <div>{errorState ? <ErrorAlert /> : null}</div>
      <div>{sucessState ? <SucessAlert /> : null}</div>
      <div>{loadingState ? <LoadingAlert /> : null}</div>

      {/* Hero section */}
      <div className="pt-8 sm:pt-10 lg:relative" style={{ minHeight: "150vh" }}>
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-24 lg:px-8">
          <FadeInSection>
            <div>
              <div className="mt-20">
                <div>
                  <a href="/Events" className="inline-flex space-x-4">
                    <span className="rounded bg-rose-50 px-2.5 py-1 text-sm font-semibold text-rose-500 animate-pulse">
                      New Events
                    </span>
                    <span className="inline-flex items-center space-x-1 text-sm font-medium text-rose-500">
                      <span>Check out our recent events here!</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </span>
                  </a>
                </div>
                <div className="mt-6 sm:max-w-xl">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    STEM Enrichment of Pennsylvania
                  </h1>
                  <div className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    <RotatingText
                      phrases={[
                        "Spreading STEM Literacy",
                        "Empowering Youth",
                        "Innovating Education",
                        "Inspiring Future Leaders",
                      ]}
                    />
                  </div>
                </div>
                <p className="mt-6 text-xl text-gray-800 leading-relaxed">
                  Proudly registered as a{" "}
                  <span className="font-semibold">
                    Government Tax-Exempt 501(c)(3) Nonprofit
                  </span>
                  , certified as a{" "}
                  <span className="font-semibold">
                    Presidential Volunteer Service Award Organization
                  </span>
                  , and recognized as{" "}
                  <span className="font-semibold">
                    Bucks County's largest secondary-student-run STEM nonprofit
                  </span>
                  .
                </p>

                {/* MailChimp Form */}
                <MailchimpForm1
                  setSucessState={setSucessState}
                  setErrorState={setErrorState}
                  setLoadingState={setLoadingState}
                />
              </div>
            </div>
          </FadeInSection>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div
            className="py-8 sm:relative sm:mt-4 sm:py-10 lg:absolute lg:top-0 lg:bottom-0 lg:right-0 lg:w-1/2"
            style={{ flexBasis: "auto" }}
          >
            <div className="flex-auto hidden sm:block">
              <div className="absolute top-0 left-1/2 h-64 rounded-l-3xl bg-gray-50 lg:left-80 lg:right-0" />
              <svg
                className="flex-auto absolute top-6 right-1/2 -mr-3 lg:left-0 lg:m-0"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={392}
                  fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                />
              </svg>
            </div>
            <div
              className="relative mx-auto max-w-4xl px-4 py-8 bg-gray-50 rounded-lg shadow-lg"
              style={{ height: "auto", maxHeight: "85vh" }}
            >
              <Splide
                options={{
                  type: "loop",
                  perPage: 1,
                  autoplay: true,
                  interval: 3000,
                  pauseOnHover: true,
                  arrows: true,
                  pagination: false,
                  speed: 800,
                  fixedHeight: "75vh"
                }}
                className="max-w-3xl mx-auto"
              >
                <SplideSlide>
                  <div className="relative w-full h-full">
                    {" "}
                    {/* Set height explicitly */}
                    <img
                      className="w-full h-full object-cover rounded-lg shadow-md" // Enforce consistent sizing
                      src="./assets/kids_at_legobins (1).jpg"
                      alt="Breadboard"
                    />
                  </div>
                </SplideSlide>

                <SplideSlide>
                  <div className="relative w-full h-full">
                    <img
                      className="w-full h-full object-cover rounded-lg shadow-md"
                      src="./assets/racing (1).jpg"
                      alt="Another"
                    />
                  </div>
                </SplideSlide>

                <SplideSlide>
                  <div className="relative w-full h-full">
                    <img
                      className="w-full h-full object-cover rounded-lg shadow-md"
                      src="./assets/WINNERS.jpg"
                      alt="Third"
                    />
                  </div>
                </SplideSlide>

                <SplideSlide>
                  <div className="relative w-full h-full">
                    <img
                      className="w-full h-full object-cover rounded-lg shadow-md"
                      src="./assets/helpingout.jpg"
                      alt="Fourth"
                    />
                  </div>
                </SplideSlide>

                <SplideSlide>
                  <div className="relative w-full h-full">
                    <img
                      className="w-full h-full object-cover rounded-lg shadow-md"
                      src="./assets/closeup.jpg"
                      alt="Fifth"
                    />
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
